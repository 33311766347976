@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Caveat:wght@500&family=Fira+Sans:wght@800;900&family=Heebo:wght@900&family=Kanit:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Mulish&display=swap');

.v-con-logo {
  font-family: 'Heebo', sans-serif;
  padding: 3px;
}

.v-con-logo-con {
  border-radius: 1.4rem;
}

.v-con-logo-con:hover {
  background-color: rgb(161, 76, 76);
  border-radius: 1.4rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}


.v-store-t {
  font-family: 'Caveat', cursive;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(128, 98, 98);
  opacity: 1;
  /* Firefox */
  font-weight: 500;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(255, 87, 87);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}

.sign-in-btn {
  border-radius: 1.4rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  color: red;
}

.sign-in-btn:hover {
  background-color: rgb(161, 76, 76);
  border-radius: 1.4rem;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.menu-link {
  max-width: max-content;
  color: rgb(61, 48, 130);
  font-size: 1rem;
  position: relative;
  cursor: pointer;
}

.menu-link::after {
  content: "";
  position: absolute;
  bottom: -13px;
  height: 2px;
  width: 100%;
  left: 0;
  background-color: rgb(10, 37, 37);
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  transform: scaleX(0);
  border-radius: 0.2rem;
}

.menu-link:hover::after {
  transform: scaleX(1);
}

.nav-container,
.footer,
.contact {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.nav-container {
  position: fixed;
  top: 0px;
}

.shop-reg {
  display: none;
  transition: 0.6s;
}


.shop-reg-con:hover>.shop-reg {
  display: flex;
  transition: 0.4s;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  bottom: 50%;
  text-shadow: 1.2px 2px 1.2px black;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  line-height: 0.2rem;
  letter-spacing: 0.03rem;
}

.logo {
  font-family: 'Libre Baskerville', serif;
  color: rgb(31, 25, 63);
  font-weight: 800;
  font-size: 22px;
}

.signupButton {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  letter-spacing: 0.03rem;
  height: 2.2rem;
  border-radius: 2rem;
  width: 10rem;
  box-shadow: rgb(61, 48, 130) 0px 2px 8px 0px, rgb(61, 48, 130) 0px 0px 0px 0.4px;
}

.nav-container {
  border-bottom: 0.3px solid rgb(61, 48, 130);
  box-shadow: rgb(61, 48, 130) 0px 2px 9px 0px, rgb(61, 48, 130) 0px 0px 0px 0.4px;
}

.car-button {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  letter-spacing: 0.03rem;
  height: 2.2rem;
  border-radius: 2rem;
  width: 10rem;
  box-shadow: rgb(61, 48, 130) 0px 2px 8px 0px, rgb(35, 24, 90) 0px 0px 0px 0.4px;
  border: 0.4px solid rgb(217, 210, 252);
  margin-top: 5px;
}

.inputField {
  box-shadow: rgb(238, 237, 238) 0px 2px 8px;
}

.memberbtncon {
  font-family: 'Mulish', sans-serif;
  font-weight: 600;
  letter-spacing: 0.03rem;
  height: 2.2rem;
  border-radius: 2rem;
  width: 100%;
  box-shadow: rgb(61, 48, 130) 0px 2px 8px 0px, rgb(61, 48, 130) 0px 0px 0px 0.4px;
  border: 0.4px solid rgb(96, 86, 144);
  display: flex;
  align-items: center;
  justify-content: space-around
}

.icons {
  width: 15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  font-size: 1.7rem;
}




.galleryContainer .galleryDrop {
  background-color: rgb(35, 24, 90);
  position: absolute;
  overflow: hidden;
  width: 0.2rem;
  opacity: 0;
  transform: translateY(-7px);
  transition: 0.23s ease-in-out;
  opacity: 0;
}

.galleryContainer:hover .galleryDrop {
  opacity: 1;
  transform: translateY(1px);
  width: 7rem;
  padding: 1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.carousel-caption {
  position: absolute;
  top: 40%;
}

@media(max-width:600px) {
  .carousel-caption {
    position: absolute;
    top: 20%;
  }
}

.mealsCon {
  background-color: rgb(237, 237, 253);
}

.textCon:hover {
  opacity: 0.7;
  cursor: pointer;
}

.textCon {
  font-size: 15px;
  color: rgb(16, 18, 67);
  font-weight: 500;
}